import { apiConfig } from '@/plugins/config'
import axios from 'axios'
export default {
  index () {
    return axios({
      method: 'get',
      url: apiConfig.apiUri + 'permission',
    })
  },
  updateUserPermission (editUserPermission) {
    return axios({
      method: 'post',
      url: apiConfig.apiUri + 'permission/updateUserPermission',
      data: {
        type: editUserPermission.type,
        user_id: editUserPermission.userId,
        permission_name: editUserPermission.permissionName,
      },
    })
  },
}
