import { apiConfig } from '@/plugins/config'
import axios from 'axios'

export default {
  store (data) {
    return axios({
      method: 'post',
      url: apiConfig.apiUri + 'admin/userCreate',
      data,
    })
  },
  update (userId, userInfo) {
    return axios({
      method: 'post',
      url: apiConfig.apiUri + 'admin/userUpdate',
      data: {
        user_id: userId,
        name: userInfo.name,
        email: userInfo.email,
        ad_username: userInfo.ad_username,
        active: userInfo.active,
        sos_list_name: userInfo.sos_list_name,
        slack_id: userInfo.slack_id,
      },
    })
  },
}
