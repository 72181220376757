<template>
  <v-container
    fluid
    tag="section"
  >
    <v-dialog
      v-model="editUserDialog"
      max-width="800px"
    >
      <v-card>
        <v-row>
          <v-col
            cols="12"
            md="7"
          >
            <v-card-title>
              <span class="text-h5">{{ UserFormTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editUser.name"
                      label="Name *"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editUser.email"
                      label="Email *"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editUser.ad_username"
                      label="AD Username"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-checkbox
                      v-model="editUser.active"
                      label="Active User"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editUser.sos_list_name"
                      label="SOS List Receiving Name"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editUser.slack_id"
                      label="Slack Id"
                    />
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    min-width="120"
                    text
                    color="red"
                    @click="editUserDialog=false"
                  >
                    Close
                  </v-btn>
                  <v-btn
                    v-if="editUserId>0"
                    min-width="120"
                    text
                    color="blue"
                    @click="updateUser"
                  >
                    Update
                  </v-btn>
                  <v-btn
                    v-else
                    min-width="120"
                    text
                    color="blue"
                    @click="createUser()"
                  >
                    Create
                  </v-btn>
                </v-card-actions>
                <v-divider></v-divider>
                <v-row>
                  <v-alert
                    v-model="alertInfo.alert"
                    dense
                    dismissible
                    outlined
                    :type="alertInfo.color"
                  >
                    {{ alertInfo.text }}
                  </v-alert>
                  <v-card-title>
                    Permissions For User (Click to remove)
                  </v-card-title>
                  <v-col cols="12">
                    <v-chip
                      v-for="(name, key) in editUser.permissions"
                      :key="key"
                      class="ma-2"
                      color="red"
                      text-color="white"
                      @click="updatePermission('remove',name)"
                    >
                      {{ name }}
                      <v-icon right>mdi-close-circle</v-icon>
                    </v-chip>
                  </v-col>
                </v-row>
                <v-row>
                  <v-card-title>
                    Permissions Not for User (Click to add)
                  </v-card-title>
                  <v-col cols="12">
                    <v-chip
                      v-for="(name, key) in permissionsNoAssignUser"
                      :key="key"
                      class="ma-2"
                      color="green"
                      text-color="white"
                      @click="updatePermission('give',name)"
                    >
                      {{ name }}
                      <v-icon right>mdi-plus-circle</v-icon>
                    </v-chip>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-col>
          <v-col
            cols="12"
            md="5"
          >
            <v-card-text>
              <v-data-table
                :headers="permissionDescriptionHeader"
                :items="permissionDescriptionData"
                :search="permissionDescriptionSearch"
                :items-per-page="8"
                class="elevation-1 mt-2"
              >
                <template v-slot:top>
                  <v-toolbar
                    flat
                  >
                    <v-toolbar-title>Permission Instruction</v-toolbar-title>
                  </v-toolbar>
                  <v-text-field
                    v-model="permissionDescriptionSearch"
                    label="Search"
                    class="mx-4"
                  ></v-text-field>
                </template>
              </v-data-table>
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-data-table
      :headers="userTableHeader"
      :items="userTableData"
      sort-by="id"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>User List</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          />
          <v-spacer />
          <v-btn
            color="primary"
            dark
            class="mb-2"
            @click="editUserDialog=true"
          >
            New User
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editRequestUser(item)"
        >
          mdi-pencil
        </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>

  import UserApi from '@/api/user'
  import UserManagementApi from '@/api/admin/user-management'
  import PermissionApi from '@/api/admin/permission'
  export default {
    name: 'UserManagement',
    data () {
      return {
        alertInfo: {
          alert: false,
          text: '',
          color: 'info',
        },
        userTableHeader: [
          { text: 'ID', align: 'start', sortable: true, value: 'id' },
          { text: 'Name', value: 'name' },
          { text: 'Email', value: 'email' },
          // { text: 'AD Username', value: 'ad_username' },
          // { text: 'Avatar', value: 'avatar' },
          // { text: 'Description', value: 'description' },
          { text: 'Active', value: 'active' },
          { text: 'SOS Receiving Name', value: 'sos_list_name' },
          // { text: 'Stack ID', value: 'slack_id' },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        userTableData: [],
        editUserId: -1,
        editUserDialog: false,
        editUser: {
          name: '',
          email: '',
          ad_username: '',
          active: false,
          sos_list_name: '',
          slack_id: '',
          permissions: '',
        },
        defaultUser: {
          name: '',
          email: '',
          ad_username: '',
          active: false,
          sos_list_name: '',
          slack_id: '',
          permissions: '',
        },
        permissions: [],
        editUserPermission: {
          type: '',
          userId: '',
          permissionName: '',
        },
        defaultUserPermission: {
          type: '',
          userId: '',
          permissionName: '',
        },
        // defaultUserPermissions: [],
        permissionsNoAssignUser: [],
        permissionDescriptionSearch: '',
        permissionDescriptionHeader: [
          { text: 'name', value: 'name' },
          { text: 'description', value: 'description' },
        ],
        permissionDescriptionData: [],
      }
    },
    computed: {
      UserFormTitle () {
        return this.editUserId > -1 ? 'Edit Use' : 'New User'
      },
    },
    mounted () {
      this.initialization()
    },
    watch: {
      editUserDialog (newVal, oldVal) {
        if (oldVal === true && newVal === false) this.close()
      },
    },
    methods: {
      initialization () {
        this.loadPermissions()
        this.loadUsers()
      },
      loadUsers () {
        UserApi.index().then(response => {
          const users = response.data.data
          this.userTableData = users
        }).catch(error => {
          console.log(error)
        })
      },
      loadUser () {
        UserApi.show(this.editUserId).then(response => {
          this.editUser = response.data.data
          this.permissionsNoAssignUser = this.permissions.filter(val => !response.data.data.permissions.includes(val))
        }).catch(error => {
          console.log(error)
        })
      },
      async editRequestUser (item) {
        this.editUserId = item.id
        await this.loadUser()
        this.editUserDialog = true
      },
      updateUser () {
        UserManagementApi.update(this.editUserId, this.editUser).then(response => {
          this.loadUser()
        }).catch(error => {
          console.log(error)
        })
      },
      createUser () {
        UserManagementApi.store(this.editUser).then(response => {
          this.editUserId = response.data.data.id
          this.loadUser()
        }).catch(error => {
          console.log(error)
        })
      },
      loadPermissions () {
        PermissionApi.index().then(response => {
          this.permissionDescriptionData = response.data.data
          response.data.data.forEach((element) => {
            this.permissions.push(element.name)
          })
        }).catch(error => {
          console.log(error)
        })
      },
      updatePermission (type, name) {
        if (this.alertInfo.alert === true) this.alertInfo.alert = false
        this.editUserPermission.type = type
        this.editUserPermission.userId = this.editUserId
        this.editUserPermission.permissionName = name
        PermissionApi.updateUserPermission(this.editUserPermission).then(response => {
          if (response.data.code !== 200) {
            this.alertInfo.alert = true
            this.alertInfo.text = response.data.message
            this.alertInfo.color = response.data.type
          }
          this.loadUser()
        }).catch(error => {
          this.alertInfo.alert = true
          this.alertInfo.text = 'Something was wrong, please contact admin or try again later'
          this.alertInfo.color = 'error'
          console.log(error)
        })
      },
      close () {
        this.editUserId = -1
        this.editUser = Object.assign({}, this.defaultUser)
        this.editUserPermission = Object.assign({}, this.defaultUserPermission)
        this.permissionsNoAssignUser = []
      },
    },
  }
</script>

<style scoped>

</style>
